// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-term-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/api-term.js" /* webpackChunkName: "component---src-pages-api-term-js" */),
  "component---src-pages-data-term-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/data-term.js" /* webpackChunkName: "component---src-pages-data-term-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jetpack-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/jetpack.js" /* webpackChunkName: "component---src-pages-jetpack-js" */),
  "component---src-pages-plans-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-woocommerce-bf-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/woocommerce_BF.js" /* webpackChunkName: "component---src-pages-woocommerce-bf-js" */),
  "component---src-pages-woocommerce-js": () => import("/opt/atlassian/pipelines/agent/build/src/services/fos-creativemail/src/pages/woocommerce.js" /* webpackChunkName: "component---src-pages-woocommerce-js" */)
}

